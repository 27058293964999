<template>
  <CRow>
    <CCol md="12">
      <CCard>
        <CCardHeader class="bg-secondary h3"
          ><i class="ri-printer-line ri-lg"></i>
          ตำบลที่พักอาศัยของนักเรียน</CCardHeader
        >
        <form
          id="formSearchClassroom"
          v-on:submit.prevent="getClassroomSubdistrict"
          method="POST"
        >
          <CCardBody>
            <CRow>
              <CCol lg="6">
                <SltLevel />
              </CCol>
              <CCol lg="6">
                <SltClass />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter class="text-right">
            <button class="btn btn-success">
              <i class="ri-file-search-line"></i>
            </button>
          </CCardFooter>
        </form>
      </CCard>
    </CCol>
    <CCol md="12">
      <CCard v-if="rows">
        <CCardBody class="table-responsive" id="single-info">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :line-numbers="true"
            :search-options="{ enabled: true }"
            :sort-options="{
              enabled: true,
              initialSortBy: { field: 'std_ordinal', type: 'asc' },
            }"
            :pagination-options="{ enabled: true, mode: 'page', perPage: 30 }"
          >
            >
            <div slot="table-actions"></div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'tools'">
                <a
                  :href="
                    'https://www.google.com/maps/dir//' +
                    props.row.std_lat +
                    ',' +
                    props.row.std_lng
                  "
                  target="_BLANK"
                  >Go</a
                >
              </span>
            </template>
          </vue-good-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import SltLevel from "./SltLevel";
import SltClass from "./SltClass";
export default {
  name: "Colors",
  components: { VueGoodTable, SltLevel, SltClass },
  data() {
    return {
      userData: this.$cookies.get("user"),
      rows: null,
      columns: [
        {
          label: "เลขที่",
          field: "std_ordinal",
          type: "number",
        },
        {
          label: "เลขประจำตัว",
          field: "stud_id",
          type: "number",
        },

        {
          label: "คำนำหน้า",
          field: "prefix_name",
          type: "text",
        },

        {
          label: "ชื่อ",
          field: "std_name",
          type: "text",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
        },
        {
          label: "ตำบล",
          field: "DISTRICT_NAME",
        },
        {
          label: "อำเภอ",
          field: "AMPHUR_NAME",
        },
        {
          label: "จังหวัด",
          field: "PROVINCE_NAME",
        },

        {
          label: "Tools",
          field: "tools",
        },
      ],
      arrCoords: [],
    };
  },
  mounted() {},
  methods: {
    getClassroomSubdistrict() {
      const form = document.getElementById("formSearchClassroom");
      const formData = new FormData(form);
      formData.append("txtAction", "getStdByLevelClassSchool");
      formData.append("levelCode", this.$store.state.selectedLevel.code);
      formData.append("class", this.$store.state.selectedClass);
      formData.append("schoolCode", this.userData.user_address);
      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.rows = response.data.mainData;
          this.arrCoords = [];
          this.rows.forEach((element) => {
            if (element.std_lat) {
              this.arrCoords.push({
                position: {
                  lat: parseFloat(element.std_lat),
                  lng: parseFloat(element.std_lng),
                },
                stdInfo: {
                  stdName: element.std_name,
                  stdSurname: element.std_surname,
                  stdTel: element.std_tel,
                },
              });
            }
          });
          this.$store.commit("setCoords", this.arrCoords);
        });
    },
  },
};
</script>
