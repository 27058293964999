var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CCard',[_c('CCardHeader',{staticClass:"bg-secondary h3"},[_c('i',{staticClass:"ri-printer-line ri-lg"}),_vm._v(" ตำบลที่พักอาศัยของนักเรียน")]),_c('form',{attrs:{"id":"formSearchClassroom","method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.getClassroomSubdistrict.apply(null, arguments)}}},[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('SltLevel')],1),_c('CCol',{attrs:{"lg":"6"}},[_c('SltClass')],1)],1)],1),_c('CCardFooter',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-success"},[_c('i',{staticClass:"ri-file-search-line"})])])],1)],1)],1),_c('CCol',{attrs:{"md":"12"}},[(_vm.rows)?_c('CCard',[_c('CCardBody',{staticClass:"table-responsive",attrs:{"id":"single-info"}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"search-options":{ enabled: true },"sort-options":{
            enabled: true,
            initialSortBy: { field: 'std_ordinal', type: 'asc' },
          },"pagination-options":{ enabled: true, mode: 'page', perPage: 30 }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'tools')?_c('span',[_c('a',{attrs:{"href":'https://www.google.com/maps/dir//' +
                  props.row.std_lat +
                  ',' +
                  props.row.std_lng,"target":"_BLANK"}},[_vm._v("Go")])]):_vm._e()]}}],null,false,2337986341)},[_vm._v(" > "),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"})])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }