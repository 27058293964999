<template>
  <div>
    <h3>แผนที่</h3>
    <GmapMap
      v-if="$store.state.coords"
      :center="centerPosition"
      :zoom="15"
      map-type-id="terrain"
      style="width: 100%; height: 600px"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in nowMarkers"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        @click="clickToNavigate(m.position.lat, m.position.lng)"
      >
        <GmapInfoWindow>{{m.stdInfo.stdName}} {{m.stdInfo.stdSurame}}<br/>
        {{m.stdInfo.stdTel}}</GmapInfoWindow>
      </GmapMarker>
    </GmapMap>
    <div v-else>กรุณาค้นหาข้อมูลที่ต้องการ</div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  props: {
    modalType: String,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
      centerPosition: { lat: 10.2, lng: 10.2 },
      //markerPointer: { position: { lat: 20, lng: 20 } },
      markers: [
        { position: { lat: 20, lng: 20 } },
        { position: { lat: 30, lng: 30 } },
      ],
      pinnedPosition: { lat: null, lng: null },
      location: null,
      gettingLocation: false,
    };
  },
  created() {
    //do we support geolocation
    if (!("geolocation" in navigator)) {
      this.errorStr = "Geolocation is not available.";
      return;
    }

    this.gettingLocation = true;
    // get current position
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.gettingLocation = false;
        this.location = pos;
        console.log(this.location);
        console.log(this.location.coords);
        this.centerPosition = {
          lat: parseFloat(this.location.coords.latitude),
          lng: parseFloat(this.location.coords.longitude),
        };
      },
      (err) => {
        this.gettingLocation = false;
        this.errorStr = err.message;
        alert("กำลัง redirect ไปยังเว็บที่สามารถใช้งาน location ได้");
        location.replace(
          "https://academic.skw.ac.th/stdAttendance/userProfile"
        );
      }
    );
  },
  mounted() {},
  computed: {
    nowMarkers() {
      return this.$store.state.coords;
    },
  },
  methods: {
    sendData() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateAbsense");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("selectedSemester", this.selectedSemester.code);
      formData.append("selectedSubject", this.selectedSubject.code);
      formData.append("txtReason", this.selectedCiteria.code);
      let jsonArr = JSON.stringify(this.selectedStudent);
      formData.append("selectedStudent", jsonArr);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == "") {
            this.showModal = true;
            this.modalSuccess = true;
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            this.showModal = true;
            this.modalSuccess = false;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => {});
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },

    updatePosition(event) {
      this.pinnedPosition.lat = event.latLng.lat();
      this.pinnedPosition.lng = event.latLng.lng();
    },
    clickToNavigate(lat, lng) {
      window.open("https://www.google.com/maps/dir//" + lat + "," + lng);
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
  width: 0px;
}
</style>
